
import { ElementRef } from '@angular/core';
import { Input, OnInit } from '@angular/core';
import { Directive} from '@angular/core';
import { SessionStorageService } from 'app/core/services/sessionStorage.service';
import { LoginResponseObj } from 'app/domain/loginResponseObj';
import { UsersService } from 'app/views/users/users.service';
import { Observable } from 'rxjs';

@Directive({
  selector: '[appAccessByRole]'
})
export class AccessByRoleDirective implements OnInit {

  @Input() appAccessByRole: string[] | string;
  currentUser : LoginResponseObj;
  roles: Observable<string[]>;
  userObjectKey: string = 'userObject';


  constructor(private elementRef: ElementRef, private userService: UsersService, private sessionStorage: SessionStorageService) {}

  ngOnInit(): void {
    // debugger;
      let comparisonArray: string[] = [];
      var userObject = new LoginResponseObj();
      const sessionStorageItem = this.sessionStorage.get(this.userObjectKey);
      this.currentUser = userObject.parseFromSessionStorage(sessionStorageItem);
      this.roles = this.userService.getUserRoles(this.currentUser.userId);
      if(Array.isArray(this.appAccessByRole))
      {
        comparisonArray = this.appAccessByRole
      }
      else
      {
        comparisonArray.push(this.appAccessByRole);
      }

      this.roles.subscribe(x => {
          //console.log("this is x: "+ JSON.stringify(x["roles"][0]));
          //console.log("this is func: "+this.appAccessByRole.includes(x["roles"][0]));

          // if( !(this.appAccessByRole.toString() == x["roles"][0]) || this.currentUser == null ){
          if( !(comparisonArray.some(y => x["roles"].includes(y))) || this.currentUser == null ){
            this.elementRef.nativeElement.style.display = 'none';
            return;
          }
          else{
            // TODO: Check if there is no css broken without this line
            // this.elementRef.nativeElement.style.display = 'block';
            return;
          }
      });
  }

}
